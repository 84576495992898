import { useEffect, useState } from "react"
import { useLocation, Link, useHistory } from "react-router-dom"
import Header from "./header";
import hamburger from '../icons/menu.svg'
import { Elements } from "@stripe/react-stripe-js";
import { getNames } from 'country-list'

import { validate } from "email-validator";
import MyBookingDetails from "./mybookingdetails"
import Footer from "./footer";
import WhoIsIn from "./whoisin";
import { CSSTransition } from "react-transition-group";
import plus from "../icons/plus.png"
import minus from "../icons/minus.png"
import FlyerDisplayer from "./flyerdisplayer";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

const charList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M",
    "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]


function Booking(params) {

    let promise = loadStripe(params.promise)

    // if (!params.selected) {
    //     window.location.href = "https://boreas.paris"
    // }

    let history = useHistory()

    const [countries, setCountries] = useState([1])
    const [bookingData, SetBookingData] = useState({
        name: "",
        firstName: "",
        address: "",
        address2: "",
        enterprise: "",
        country: "France",
        city: "",
        zipCode: "",
        phone: "",
        email: "",
        gender: "Monsieur"
    })

    const [actualQuery, setActualQuery] = useState(1)
    const [actualStep, setActualStep] = useState(1)
    const [maxStep, setMaxStep] = useState(1)
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState(null);
    const [numberOfGuests, setNumberOfGuests] = useState(params.selected ? params.selected.pairBooking ? 2 : 1 : 1)
    const [numberOfKid, setNumberOfKid] = useState(0)
    const [disabledPerson, setDisabledPerson] = useState(false)
    const [price, setPrice] = useState(0)
    const [popup, setPopup] = useState(false)
    const [isEmailValid, setIsEmailValid] = useState(false)
    const [bookingCode, SetBookingCode] = useState(null)
    const [vipAvailable, setVipAvailable] = useState(params.selected ? params.selected.vipAvailable : false)
    const [vipOption, setVipOption] = useState(false)
    const [moreInfo, setMoreInfo] = useState(false)
    const isMobile = (params.ratio.width < 600 || params.ratio.width < params.ratio.height) ? true : false
    const [scrollPos, setScrollPos] = useState(0)
    const [displayHeader, setDisplayHeader] = useState(true)
    const [displayMobileMenu, setDisplayMobileMenu] = useState(false)
    const [burgerOrClose, setBurgerOrClose] = useState(hamburger)

    const [tenPeopleWarning, setTenPeoleWarning] = useState(false)
    const [menu1count, setMenu1Count] = useState(0)
    const [menu2count, setMenu2Count] = useState(0)
    const [option1Chosen, setOption1Chosen] = useState(0)
    const [option2Chosen, setOption2Chosen] = useState(0)
    const [option1Remaining, setOption1Remaining] = useState(0)
    const [option2Remaining, setOption2Remaining] = useState(0)
    const [flyerSrc, setFlyerSrc] = useState(null)
    const [truncatedFlyer, setTruncatedFlyer] = useState(false)
    const [flyerQuery, setFlyerQuery] = useState(false)
    const [formOK, setFormOK] = useState(false)
    const [nextStepButton, setNextStepButton] = useState("étape suivante")
    const [amountBooked, setAmountBooked] = useState(0)
    const [paymentWarning, setPaymentWarning] = useState(true)
    const [paymentIntent, setPaymentIntent] = useState(null)
    const [bookingResponse, setBookingResponse] = useState(null)
    const [bookingFromCache, setBookingFromCache] = useState(null)
    const [confirmedBooking, setConfirmedBooking] = useState(false)


    const [promoCode, setPromoCode] = useState("Code promo")
    const [promoAmount, setPromoAmount] = useState(0)
    const [hideMBD, setHideMBD] = useState(false)
    // const [hideValentinePromo, setHideValentinePromo] = useState(false)

    console.log(`actual step : ${actualStep} \n maxstep : ${maxStep}`)
    console.log("option1Remaining", option1Remaining)

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();
    let queryEvent = query.get("step")
    let queryAdvertiser = query.get("advertiser")
    let queryEventName = query.get("event")

    useEffect(() => {
        console.log("in useeffect for query event name")
        console.log("query event name", queryEventName)
        console.log("params.selected", params.selected)
        if (!params.eventLoaded) {
            return
        }
        console.log("query event name", queryEventName)
        console.log("params.selected", params.selected)
        if (queryEventName && !params.selected) {
            console.log("query event name", queryEventName)
            let event = params.activeEvents.find(e => e.title === queryEventName)
            if (event) {
                params.setSelected(event)
            }
            else {
                console.log("event not found")
            }
        } else if (!queryEventName) {
            console.log("no query event name")
        }
    }, [params.eventLoaded, queryEventName])


    const [advertiser, setAdvertiser] = useState(queryAdvertiser)

    console.log(`queryAdvertiser : ___ ${queryAdvertiser}`)

    console.log("query event", queryEvent)
    console.log("actual query", actualQuery)

    console.log(`confirmed booking : ${confirmedBooking}`)
    console.log("booking from cache", bookingFromCache)

    if (queryEvent !== actualQuery) {
        setActualQuery(queryEvent)
    }

    let queryFlyer = query.get("displayflyer")

    if (!queryFlyer) {
        if (flyerQuery === true) {
            setFlyerQuery(false)
        }
    } else if (queryFlyer === "true") {
        if (flyerQuery === false) {
            setFlyerQuery(true)
        }
    }

    useEffect(() => {
        if (flyerQuery === false) {
            HandleFlyerClick()
        }
    }, [flyerQuery])

    function buildRecapObject() {
        let recapObject = {
            ...params.selected,
            paymentIntent: paymentIntent,
            email: bookingData.email,
            bookingCode: bookingCode,
            promoAmount: promoAmount * numberOfGuests,
            eventName: params.selected.title,
            price: price / 100,
            date: params.chosenDate.toLocaleDateString(params.language),
            day: params.chosenDate.toLocaleDateString('fr-FR', { weekday: 'long' }),
            requestMode: false,
            vipOption: vipOption,
            numberOfGuests: numberOfGuests,
            numberOfKid: numberOfKid,
            kidPrice: vipOption ? params.selected.vipKidPrice : params.selected.kidPrice,
            adultPrice: vipOption ? params.selected.vipPrice : params.selected.price,
            optionPrice: option1Chosen * params.selected.option1Price + option2Chosen * params.selected.option2Price
        }
        console.log("recap object :", recapObject)
        localStorage.removeItem('ongoingBooking')
        localStorage.setItem('ongoingBooking', JSON.stringify(recapObject))
    }

    useEffect(() => {
        console.log("In da useeffect yo !!!")
        if (actualQuery === "confirmbooking") {
            setMaxStep(5)
            console.log("in da actualquery === confirmbooking")
            setConfirmedBooking(true)
            let cachedBooking = JSON.parse(localStorage.getItem('ongoingBooking'))
            if (cachedBooking) {
                console.log("parsed test : ", (cachedBooking))
                setBookingFromCache(cachedBooking)
                localStorage.removeItem('ongoingBooking')
                setActualStep(5)
            }
        }
        else if (actualQuery && actualQuery <= maxStep) {
            setActualStep(parseInt(actualQuery, 10))
            if (parseInt(actualQuery, 10) === 4) {
                if (!paymentIntent) {
                    callStripe()
                }
            }
            HandleFlyerClick()
        } else {
            setActualStep(1)
            HandleFlyerClick()
        }
    }, [actualQuery])

    function handleScroll() {
        if (window.scrollY > scrollPos) {
            if (window.scrollY > 50) {
                setDisplayHeader(false)
                setDisplayMobileMenu(false)
            }
            setScrollPos(window.scrollY)
        } else {
            setDisplayHeader(true)
            setScrollPos(window.scrollY)
        }
        let bg = document.getElementById("main-bg")
        if (window.scrollY < 1000 && window.scrollY > 200) {
            if (bg) {
                bg.style.filter = `brightness(120%) blur(${Math.floor((window.scrollY - 200) / 100)}px)`
            }
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [scrollPos])

    function callStripe() {
        fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/create-payment-intent`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                items:
                {
                    name: params.selected.title,
                    numberOfGuests: numberOfGuests,
                    numberOfKid: numberOfKid,
                    bookingData: bookingData,
                    vipOption: vipOption,
                    promoAmount: promoAmount,
                    additionalPrice: option1Chosen * params.selected.option1Price + option2Chosen * params.selected.option2Price
                }
            })
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                setClientSecret(data.clientSecret)
                setPaymentIntent(data.paymentIntent)
                console.log("data client secret :", data.clientSecret)
                console.log("data payment intent :", data.paymentIntent)
                setPrice(data.price)
            });
    }

    function getBookingCode() {
        let str = ""
        for (let i = 0; i < 12; i++) {
            str += charList[Math.floor(Math.random() * charList.length)]
        }
        return str
    }

    useEffect(() => {
        let countrylist = getNames()
        setCountries(countrylist)
        SetBookingCode(getBookingCode())
    }, [])

    // useEffect(() => {
    //     if (vipOption) {
    //         if (numberOfGuests < 10) {
    //             setNumberOfGuests(10)
    //         }
    //     }
    //     clearTimeout(calltimer)
    //     calltimer = setTimeout(() => {
    //         callStripe()
    //     }, 200);
    // }, [numberOfGuests, numberOfKid, vipOption])

    useEffect(() => {
        if (validate(bookingData.email)) {
            setIsEmailValid(true)
        } else {
            setIsEmailValid(false)
        }
    }, [bookingData.email])

    useEffect(() => {
        if (!params.selected) {
            return
        }
        if (maxStep < 5) {
            setMaxStep(1)
        }
        setMenu1Count(0)
        setMenu2Count(0)
        if (!params.selected.menu2Link) {
            setMenu1Count(numberOfGuests)
        }
        if (option1Chosen && params.selected.option1Detail.multiplyByPeople) {
            setOption1Chosen(numberOfGuests + numberOfKid)
        }
        if (option2Chosen && params.selected.option2Detail.multiplyByPeople) {
            setOption2Chosen(numberOfGuests + numberOfKid)
        }
    }, [numberOfGuests, numberOfKid])

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    useEffect(() => {
        if (!params.selected) {
            return
        }
        fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/getoptionsbooked`, {
            method: "POST",
            body: JSON.stringify({
                year: params.chosenDate.getUTCFullYear(),
                month: params.chosenDate.getUTCMonth() + 1,
                day: params.chosenDate.getUTCDate(),
                eventName: params.selected.title
            }),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                console.log("data.option1", data.option1)
                console.log("data.option2", data.option2)
                setOption1Remaining(params.selected.option1Detail.limitAvailable - data.option1)
                setOption2Remaining(params.selected.option2Detail.limitAvailable - data.option2)
            });
        setMenuAndOptionDivSize()
    }, [])

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };

    // function sendBooking() {
    //     console.log("*** sendBooking called")
    //     fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/bookinginfo`, {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({
    //             items:
    //             {
    //                 name: params.selected.title,
    //                 numberOfGuests: numberOfGuests,
    //                 numberOfKid: numberOfKid,
    //                 disabledPerson: disabledPerson,
    //                 bookingData: bookingData,
    //                 price: price / 100,
    //                 language: params.language,
    //                 date: params.chosenDate.toLocaleDateString(params.language),
    //                 year: params.chosenDate.getUTCFullYear(),
    //                 month: params.chosenDate.getUTCMonth() + 1,
    //                 day: params.chosenDate.getUTCDate(),
    //                 bookingCode: bookingCode,
    //                 vipOption: vipOption,
    //                 menu: !vipAvailable ? `${params.selected.menu1Title} : ${menu1count}
    //                      / ${params.selected.menu2Title} : ${menu2count}` : "",
    //                 options: {
    //                     option1: params.selected.option1,
    //                     option1Amount: option1Chosen,
    //                     option2: params.selected.option2,
    //                     option2Amount: option2Chosen,
    //                 },
    //                 paymentIntent: paymentIntent,
    //                 stopoverName: params.selected.stopoverName,
    //                 stopoverLink: params.selected.stopoverLink,
    //                 leavingAt: params.selected.leavingAt,
    //                 returningAt: params.selected.returningAt,
    //                 stopover: params.selected.stopover,
    //                 advertiser: advertiser
    //             }
    //         })
    //     }).then(res => {
    //         return res.json();
    //     })
    //         .then(data => {
    //             if (data.response === "booking ok") {
    //                 setBookingResponse("booking ok")
    //                 return
    //             }
    //         })
    // }

    function sendBooking() {
        console.log("*** sendBooking called");
    
        if (!navigator.onLine) {
            console.log("Network is offline");
            setError("Network error: You are offline.");
            setPopup(true); // Ensure the error message is displayed in the UI
            return;
        }
    
        const controller = new AbortController();
        const timeoutId = setTimeout(() => {
            console.log("Timeout reached, aborting fetch");
            controller.abort();
            setError("Network error: The request took too long to complete.");
            setPopup(true); // Ensure the error message is displayed in the UI
        }, 2000);
    
        try {
            fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/bookinginfo`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    items: {
                        name: params.selected.title,
                        numberOfGuests: numberOfGuests,
                        numberOfKid: numberOfKid,
                        disabledPerson: disabledPerson,
                        bookingData: bookingData,
                        price: price / 100,
                        language: params.language,
                        date: params.chosenDate.toLocaleDateString(params.language),
                        year: params.chosenDate.getUTCFullYear(),
                        month: params.chosenDate.getUTCMonth() + 1,
                        day: params.chosenDate.getUTCDate(),
                        bookingCode: bookingCode,
                        vipOption: vipOption,
                        menu: !vipAvailable ? `${params.selected.menu1Title} : ${menu1count} / ${params.selected.menu2Title} : ${menu2count}` : "",
                        options: {
                            option1: params.selected.option1,
                            option1Amount: option1Chosen,
                            option2: params.selected.option2,
                            option2Amount: option2Chosen,
                        },
                        paymentIntent: paymentIntent,
                        stopoverName: params.selected.stopoverName,
                        stopoverLink: params.selected.stopoverLink,
                        leavingAt: params.selected.leavingAt,
                        returningAt: params.selected.returningAt,
                        stopover: params.selected.stopover,
                        advertiser: advertiser
                    }
                }),
                signal: controller.signal
            }).then(res => {
                clearTimeout(timeoutId);
                console.log("Fetch completed successfully");
                return res.json();
            }).then(data => {
                if (data.response === "booking ok") {
                    setBookingResponse("booking ok");
                    return;
                }
            }).catch(err => {
                clearTimeout(timeoutId); // Ensure the timeout is cleared in case of an error
                console.log('Fetch failed with error:', err);
                if (err.name === 'AbortError') {
                    console.log('Fetch aborted due to timeout');
                } else {
                    setError("Network error: Unable to complete the request.");
                    setPopup(true); // Ensure the error message is displayed in the UI
                }
            });
        } catch (err) {
            clearTimeout(timeoutId); // Ensure the timeout is cleared in case of an error
            console.log('Fetch failed with synchronous error:', err);
            setError("Network error: Unable to complete the request.");
            setPopup(true); // Ensure the error message is displayed in the UI
        }
    }

    // const handleSubmit = async ev => {
    //     if ((vipOption && numberOfGuests < 10) || (!vipAvailable && menu1count + menu2count < numberOfGuests)) {
    //         return
    //     }
    //     ev.preventDefault();
    //     setProcessing(true);
    //     window.scrollTo({ top: 0, behavior: "smooth" })
    //     const payload = await stripe.confirmCardPayment(clientSecret, {
    //         receipt_email: bookingData.email,
    //         payment_method: {
    //             card: elements.getElement(CardElement)
    //         }
    //     });
    //     if (payload) {
    //         console.log("PAYLOAD", payload)
    //     }
    //     if (payload.error) {
    //         setError(`Payment failed ${payload.error.message}`);
    //         setProcessing(false);
    //         setPopup(true)
    //     } else {
    //         setError(null);
    //         setProcessing(false);
    //         setSucceeded(true);
    //         setMaxStep(5)
    //         history.push({
    //             pathname: "/booking",
    //             search: "?step=5"
    //         })
    //         setTimeout(() => {
    //             window.scrollTo({ top: 0, behavior: "smooth" })
    //         }, 50);
    //         fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/confirmation-email`, {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify({
    //                 items:
    //                 {
    //                     name: params.selected.title,
    //                     numberOfGuests: numberOfGuests,
    //                     numberOfKid: numberOfKid,
    //                     disabledPerson: disabledPerson,
    //                     bookingData: bookingData,
    //                     price: price / 100,
    //                     language: params.language,
    //                     date: params.chosenDate.toLocaleDateString(params.language),
    //                     year: params.chosenDate.getUTCFullYear(),
    //                     month: params.chosenDate.getUTCMonth() + 1,
    //                     day: params.chosenDate.getUTCDate(),
    //                     bookingCode: bookingCode,
    //                     vipOption: vipOption,
    //                     menu: !vipAvailable ? `${params.selected.menu1Title} : ${menu1count}
    //                      / ${params.selected.menu2Title} : ${menu2count}` : "",
    //                     options: {
    //                         option1: params.selected.option1,
    //                         option1Amount: option1Chosen,
    //                         option2: params.selected.option2,
    //                         option2Amount: option2Chosen,
    //                     }
    //                 }
    //             })
    //         }).then(res => {
    //             return res.json();
    //         })
    //             .then(data => {
    //                 if (data.response === "booking ok") {
    //                     return
    //                 }
    //             })
    //     }
    // };

    function handleFrameClick(flyer) {
        setTruncatedFlyer(true)
        setFlyerSrc(flyer)
        setDisplayHeader(false)
        history.push({
            search: `?step=${queryEvent}&displayflyer=true`
        })
    }

    function HandleFlyerClick(params) {
        setTruncatedFlyer(false)
        setDisplayHeader(true)
    }

    function compareFields(redFields) {
        if (!redFields) {
            let check = true
            Object.entries(bookingData).forEach(([k, v]) => {
                if (bookingData[k] === "" && k !== "enterprise" && k !== "address2") {
                    check = false
                }
            });
            return check
        } else {
            let lst = []
            Object.entries(bookingData).forEach(([k, v]) => {
                if (bookingData[k] === "" && k !== "enterprise" && k !== "address2") {
                    lst.push(k)
                }
            });
            return lst
        }
    }

    useEffect(() => {
        if (formOK) {
            setNextStepButton("étape suivante")
        }
    }, [formOK])

    useEffect(() => {
        setFormOK(compareFields(false))
    }, [bookingData])

    useEffect(() => {
        if (!params.selected) {
            return
        }
        fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/getbookingsamountperdate`, {
            method: "POST",
            body: JSON.stringify({
                year: params.chosenDate.getUTCFullYear(),
                month: params.chosenDate.getUTCMonth() + 1,
                day: params.chosenDate.getUTCDate(),
                eventName: params.selected.title
            }),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                setAmountBooked(data.amount)
            });
    }, [params.chosenDate])

    function setMenuAndOptionDivSize() {
        let listToHomogenize = ["option1", "option2", "menu1", "menu2", "kidmenu"]
        let biggest = 0
        listToHomogenize.forEach(e => {
            let elem = document.getElementById(e)
            if (elem) {
                console.log("elem :________", elem)
                console.log("elem.getBoundingClientRect.width", elem.getBoundingClientRect().width)
                if (elem.getBoundingClientRect().width > biggest) {
                    biggest = elem.getBoundingClientRect().width
                }
            }
        })
        console.log("biggest", biggest)
        listToHomogenize.forEach(e => {
            let elem = document.getElementById(e)
            if (elem) {
                console.log("elem exists !!!")
                elem.style.width = `${biggest}px`
            }
        })
    }

    function callPromo() {
        fetch(`https://boreasapi.cyrilmorin.fr:${params.servPort}/getpromo`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                code: promoCode
            })
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                console.log(data.promoAmount)
                setPromoAmount(data.promoAmount)
            });
    }

    useEffect(() => {
        setHideMBD(true)
        setTimeout(() => {
            setHideMBD(false)
        }, 100);
    }, [promoAmount])

    // let test = document.getElementById("option1")
    // if (test){
    //     console.log("option1 rect: ", test.getBoundingClientRect())
    // }

    const appearance = {
        theme: 'night',
    };

    const options = {
        clientSecret,
        appearance,
    };

    console.log("booking data", bookingData)

    if (!params.selected) {
        return (
            <p>loading...</p>
        )
    } else {



        return (
            <div
                className="form-wrapper"
            >
                <div id="main-bg">

                </div>
                <CSSTransition
                    mountOnEnter
                    unmountOnExit
                    in={truncatedFlyer}
                    timeout={500}
                    classNames="flyer-transition"
                >
                    <FlyerDisplayer
                        style={{
                            zIndex: 100
                        }}
                        src={flyerSrc}
                        HandleFlyerClick={HandleFlyerClick}
                        setTruncatedFlyer={setTruncatedFlyer} />
                </CSSTransition>
                <Header
                    isMobile={isMobile}
                    displayHeader={displayHeader}
                    setDisplayMobileMenu={setDisplayMobileMenu}
                    displayMobileMenu={displayMobileMenu}
                    burgerOrClose={burgerOrClose}
                />
                {popup && <div
                    className="pop-up-wrapper"
                >
                    {error && (
                        <div
                            className="pop-up"
                        >
                            <div className="card-error" role="alert">
                                <p
                                    className="pop-up-text"
                                >{error}</p>
                            </div>

                            <button
                                onClick={() => setPopup(false)}
                                className="pop-up-button"
                            ><p
                                className="pop-up-text"
                            >Fermer</p></button>
                        </div>)}
                </div>}
                {
                    paymentWarning && actualStep === 4 &&
                    <div id="payment-warning">
                        <p>Si le paiement requiert une authentification de la part de votre banque, veuillez
                            revenir à la boutique afin de recevoir votre code de réservation et votre mail de confirmation.
                        </p>
                        <button
                            onClick={() => setPaymentWarning(false)}
                        >"J'ai compris !"</button>
                    </div>
                }
                {/* {
                !hideValentinePromo && actualStep === 1 &&
                <div id="love-warning">
                <p>40€ de réduction par personne avec le code promo "LOVE"</p>
                <button
                onClick={() => setHideValentinePromo(true)}
                >"J'ai compris !"</button>
                </div>
            } */}
                {/* <div
                className="booking-title"
            >
                <h1 className="boreas-subtitle booking-title-text">
                    {`Réservation pour ${params.selected.title} le ${params.chosenDate.toLocaleDateString(params.language)}`}
                </h1>
            </div> */}
                <div id="booking-steps-wrapper">
                    <button
                        className="booking-step"
                        onClick={() => history.push({
                            pathname: "/booking",
                            search: "?step=1"
                        })}
                        style={{
                            backgroundColor: actualStep === 1 ?
                                "black" : "rgba(0, 0, 0, 0.63)"
                        }}
                    >{isMobile ? "1" : "1 - événnement"}
                    </button>
                    <button
                        className="booking-step"
                        onClick={() => {
                            if (maxStep >= 2) {
                                history.push({
                                    pathname: "/booking",
                                    search: "?step=2"
                                })
                            }
                        }}
                        style={{
                            backgroundColor: actualStep === 2 ?
                                "black" : "rgba(0, 0, 0, 0.63)"
                        }}
                    >{isMobile ? "2" : "2 - coordonées"}
                    </button>
                    <button
                        className="booking-step"
                        onClick={() => {
                            if (maxStep >= 3) {
                                history.push({
                                    pathname: "/booking",
                                    search: "?step=3"
                                })
                            }
                        }}
                        style={{
                            backgroundColor: actualStep === 3 ?
                                "black" : "rgba(0, 0, 0, 0.63)"
                        }}
                    >{isMobile ? "3" : "3 - panier"}
                    </button>
                    <button
                        className="booking-step"
                        onClick={() => {
                            if (maxStep >= 4) {
                                history.push({
                                    pathname: "/booking",
                                    search: "?step=4"
                                })
                            }
                        }}
                        style={{
                            backgroundColor: actualStep === 4 ?
                                "black" : "rgba(0, 0, 0, 0.63)"
                        }}
                    >{isMobile ? "4" : "4 - paiement"}
                    </button>
                    <button
                        className="booking-step"
                        onClick={() => {
                            if (maxStep >= 5) {
                                history.push({
                                    pathname: "/booking",
                                    search: "?step=5"
                                })
                            }
                        }}
                        style={{
                            backgroundColor: actualStep === 5 ?
                                "black" : "rgba(0, 0, 0, 0.63)"
                        }}
                    >{isMobile ? "5" : "5 - confirmation"}
                    </button>
                </div>
                {
                    actualStep === 1 &&
                    <div>
                        <div id="booking-step1-wrapper">
                            <div id="booking-step1-event">
                                <img src={`https://pics.boreas.paris/${params.selected.picture}`}
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    alt="event picture"
                                    onClick={() => handleFrameClick(`https://pics.boreas.paris/${params.selected.picture}`)}
                                />
                                <div id="booking-step1-event-text">
                                    <p id="booking-step1-event-text-title">événnement</p>
                                    <p id="booking-step1-event-text-title-name">
                                        {params.selected.title}
                                    </p>
                                    <p id="booking-step1-event-text-date">
                                        {`${params.chosenDate.toLocaleDateString('fr-FR', { weekday: 'long' })} ${params.chosenDate.toLocaleDateString(params.language)}`}
                                    </p>
                                    <p id="booking-step1-event-text-location">lieu embarquement</p>
                                    <p id="booking-step1-event-text-stopover-name">Escale : <a
                                        style={{
                                            textDecoration: "underline"
                                        }}
                                        target="_blank"
                                        href={params.selected.stopoverLink}>
                                        {params.selected.stopoverName}</a></p>
                                    <p id="booking-step1-event-text-stopover-text">{params.selected.stopover}</p>
                                    <p id="booking-step1-event-text-leaving">{params.selected.leavingAt}</p>
                                    <p className="booking-step1-event-text-boarding">Embarquement minimum 30 minutes avant le départ</p>
                                    <p className="booking-step1-event-text-boarding">présentation du <span
                                        style={{
                                            textDecoration: "underline"
                                        }}
                                    >pass sanitaire</span> selon les mesures en vigueur</p>
                                </div>
                                <p id="booking-step1-who">QUI PARTICIPE</p>
                                <WhoIsIn
                                    numberOfGuests={numberOfGuests}
                                    numberOfKid={numberOfKid}
                                    disabledPerson={disabledPerson}
                                    setNumberOfGuests={setNumberOfGuests}
                                    setNumberOfKid={setNumberOfKid}
                                    setDisabledPerson={setDisabledPerson}
                                    isMobile={isMobile}
                                    pairBooking={params.selected.pairBooking}
                                    limitBooking={params.selected.maximumCapacity - amountBooked}
                                />
                            </div>
                        </div>
                        <div id="ticket-select-div">
                            <p id="ticket-select-title">Type de Réservation</p>
                            <div id="ticket-boreas"
                                style={{
                                    border: vipAvailable ? !vipOption ? "solid 5px rgb(242, 135, 5)" : "solid 2px rgb(242, 135, 5)" : "solid 2px rgb(242, 135, 5)",
                                    transform: vipOption ? "scale(0.8)" : "scale(1)"
                                }}
                                onClick={() => setVipOption(false)}
                            >
                                <p id="ticket-boreas-title">TICKET BOREAS</p>
                                <div className="ticket-separation-bar" />
                                <p className="ticket-tariff">Tarif par adulte : {params.selected.price} €</p>
                                {!params.selected.pairBooking && <p className="ticket-tariff">Tarif par enfant : {params.selected.kidPrice} €</p>}
                                <div className="ticket-separation-bar" />
                                <p className="ticket-tariff">Total : {params.selected.price * numberOfGuests + params.selected.kidPrice * numberOfKid} €</p>
                            </div>
                            {
                                vipAvailable &&
                                <div id="ticket-vip"
                                    style={{
                                        border: vipOption ? "solid 5px rgb(242, 135, 5)" : "solid 2px rgb(242, 135, 5)",
                                        transform: !vipOption ? "scale(0.8)" : "scale(1)"
                                    }}
                                    onClick={() => {
                                        setVipOption(true)
                                        if (numberOfGuests < 10) {
                                            setTenPeoleWarning(true)
                                            setTimeout(() => {
                                                setTenPeoleWarning(false)
                                            }, 2000);
                                        }
                                    }}
                                >
                                    <p
                                        style={{
                                            color: vipOption ? "rgb(255, 200, 77)" : "rgb(242, 135, 5)"
                                        }}
                                        id="ticket-boreas-title">TICKET VIP BOREAS</p>
                                    <div className="ticket-separation-bar" />
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                            justifyContent: "space-around"
                                        }}
                                    >
                                        <p className="ticket-tariff">Adulte : {params.selected.vipPrice} €</p>
                                        <p className="ticket-tariff">Enfant : {params.selected.vipKidPrice} €</p>
                                    </div>
                                    <p className="ticket-tariff">Accès au bateau</p>
                                    <p className="ticket-tariff">Sweet corner</p>
                                    {
                                        !tenPeopleWarning &&
                                        <p className="ticket-tariff">Un minimum de 10 personnes est requis</p>
                                    }
                                    {
                                        tenPeopleWarning &&
                                        <p className="ticket-tariff ten-people-transition">10 Adultes minimum pour le ticket VIP</p>
                                    }
                                    <div className="ticket-separation-bar" />
                                    <p className="ticket-tariff">Total : {params.selected.vipPrice * numberOfGuests + params.selected.vipKidPrice * numberOfKid} €</p>
                                    <p
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        onClick={() => setMoreInfo(!moreInfo)}
                                        className="ticket-tariff"
                                        id="more-info-vip"
                                    >plus d'informations</p>
                                    {
                                        moreInfo &&
                                        <div id="vip-info-div">
                                            <p>{params.selected.vipText}</p>
                                        </div>
                                    }
                                </div>
                            }


                        </div>
                        {
                            (!vipAvailable && params.selected.menu1Title) &&
                            <div id="menu-choice">
                                <p id="menu-choice-title">CHOIX DES MENUS</p>
                                <div className="menu-count-wrapper" id="menu1">
                                    <a className="menu-description"
                                        target="_blank"
                                        href={`https://pics.boreas.paris/menus/${params.selected.menu1Link}`}>{params.selected.menu1Title}</a>
                                    <div className="who-is-in-plus-minus">
                                        <img src={minus} className="plus-minus-icon"
                                            style={{
                                                filter: menu1count === 0 ?
                                                    "invert(75%)" : "invert(100%)"
                                            }}
                                            onClick={() => {
                                                if (menu1count === 0) {
                                                    return
                                                } else {
                                                    setMenu1Count(menu1count - 1)
                                                }
                                            }}
                                        />
                                        <p className="who-is-in-num">{menu1count}</p>
                                        <img src={plus} className="plus-minus-icon"
                                            onClick={() => {
                                                if (menu1count < numberOfGuests) {
                                                    if (menu1count + menu2count === numberOfGuests) {
                                                        setMenu1Count(menu1count + 1)
                                                        setMenu2Count(menu2count - 1)
                                                    } else {
                                                        setMenu1Count(menu1count + 1)
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                {
                                    params.selected.menu2Title &&
                                    <div className="menu-count-wrapper" id="menu2">
                                        <a className="menu-description"
                                            target="_blank"
                                            href={`https://pics.boreas.paris/menus/${params.selected.menu2Link}`}>{params.selected.menu2Title}</a>
                                        <div className="who-is-in-plus-minus">
                                            <img src={minus} className="plus-minus-icon"
                                                style={{
                                                    filter: menu2count === 0 ?
                                                        "invert(75%)" : "invert(100%)"
                                                }}
                                                onClick={() => {
                                                    if (menu2count === 0) {
                                                        return
                                                    } else {
                                                        setMenu2Count(menu2count - 1)
                                                    }
                                                }}
                                            />
                                            <p className="who-is-in-num">{menu2count}</p>
                                            <img src={plus} className="plus-minus-icon"
                                                onClick={() => {
                                                    if (menu2count < numberOfGuests) {
                                                        if (menu1count + menu2count === numberOfGuests) {
                                                            setMenu2Count(menu2count + 1)
                                                            setMenu1Count(menu1count - 1)
                                                        } else {
                                                            setMenu2Count(menu2count + 1)
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>}
                                {
                                    params.selected.kidMenuLink &&
                                    <div className="menu-count-wrapper" id="kidmenu">
                                        <a className="menu-description"
                                            target="_blank"
                                            href={`https://pics.boreas.paris/menus/${params.selected.kidMenuLink}`}>Menu Enfants</a>
                                        <div className="who-is-in-plus-minus">

                                            <p className="who-is-in-num">{numberOfKid}</p>
                                        </div>
                                    </div>}
                            </div>
                        }
                        {
                            (!vipAvailable && params.selected.option1) &&
                            <div id="option-div">
                                <p id="options-title">OPTIONS</p>
                                {
                                    !(params.selected.option1Detail.multiplyByPeople && (numberOfGuests + numberOfKid) > option1Remaining) &&
                                    <div className="option" id="option1">
                                        <div className="option-title-and-checkbox">
                                            <p className="option-title">{`${params.selected.option1}: ${params.selected.option1Price}€`}</p>
                                            {
                                                !params.selected.option1Detail.multiplyByPeople &&
                                                <div className="who-is-in-plus-minus">
                                                    <img src={minus} className="plus-minus-icon"
                                                        style={{
                                                            filter: option1Chosen === 0 ?
                                                                "invert(75%)" : "invert(100%)"
                                                        }}
                                                        onClick={() => {
                                                            if (option1Chosen === 0) {
                                                                return
                                                            } else {
                                                                setOption1Chosen(option1Chosen - 1)
                                                            }
                                                        }}
                                                    />
                                                    <p className="who-is-in-num">{option1Chosen}</p>
                                                    <img src={plus} className="plus-minus-icon"
                                                        onClick={() => {
                                                            if (option1Chosen < option1Remaining || params.selected.option1Detail.limitAvailable === 0) {
                                                                if (!params.selected.option1Detail.limitPerPersonOrGroup) {
                                                                    setOption1Chosen(option1Chosen + 1)
                                                                } else if (params.selected.option1Detail.limitPerPersonOrGroupType === "group") {
                                                                    if (option1Chosen < params.selected.option1Detail.limitPerPersonOrGroup) {
                                                                        setOption1Chosen(option1Chosen + 1)
                                                                    }
                                                                } else if (params.selected.option1Detail.limitPerPersonOrGroupType === "person") {
                                                                    if (option1Chosen < params.selected.option1Detail.limitPerPersonOrGroup * numberOfGuests) {
                                                                        setOption1Chosen(option1Chosen + 1)
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>}
                                            {
                                                (params.selected.option1Detail.multiplyByPeople && (numberOfGuests + numberOfKid) <= option1Remaining) &&
                                                <input
                                                    type="checkbox"
                                                    defaultChecked={option1Chosen}
                                                    onChange={() => option1Chosen ? setOption1Chosen(0) : setOption1Chosen(numberOfGuests)}
                                                />}
                                            {/* <input
                                        style={{
                                            height: "40px"
                                        }}
                                        type="checkbox"
                                        onChange={() => setOption1Chosen(!option1Chosen)}
                                        defaultChecked={option1Chosen}
                                    /> */}
                                        </div>
                                        <p className="option-description">{params.selected.option1Description}</p>
                                    </div>}
                                {
                                    (params.selected.option2 && !(params.selected.option2Detail.multiplyByPeople && (numberOfGuests + numberOfKid) > option2Remaining)) &&
                                    <div className="option" id="option2">
                                        <div className="option-title-and-checkbox">
                                            <p className="option-title">{`${params.selected.option2}: ${params.selected.option2Price}€`}</p>
                                            {/* <input
                                            style={{
                                                height: "40px"
                                            }}
                                            type="checkbox"
                                            onChange={() => setOption2Chosen(!option2Chosen)}
                                            defaultChecked={option2Chosen}
                                        /> */}
                                            {
                                                !params.selected.option2Detail.multiplyByPeople &&
                                                <div className="who-is-in-plus-minus">
                                                    <img src={minus} className="plus-minus-icon"
                                                        style={{
                                                            filter: option2Chosen === 0 ?
                                                                "invert(75%)" : "invert(100%)"
                                                        }}
                                                        onClick={() => {
                                                            if (option2Chosen === 0) {
                                                                return
                                                            } else {
                                                                setOption2Chosen(option2Chosen - 1)
                                                            }
                                                        }}
                                                    />
                                                    <p className="who-is-in-num">{option2Chosen}</p>
                                                    <img src={plus} className="plus-minus-icon"
                                                        onClick={() => {
                                                            if (option2Chosen < option2Remaining || params.selected.option1Detail.limitAvailable === 0) {
                                                                if (!params.selected.option2Detail.limitPerPersonOrGroup) {
                                                                    setOption2Chosen(option2Chosen + 1)
                                                                } else if (params.selected.option2Detail.limitPerPersonOrGroupType === "group") {
                                                                    if (option2Chosen < params.selected.option2Detail.limitPerPersonOrGroup) {
                                                                        setOption2Chosen(option2Chosen + 1)
                                                                    }
                                                                } else if (params.selected.option2Detail.limitPerPersonOrGroupType === "person") {
                                                                    if (option2Chosen < params.selected.option2Detail.limitPerPersonOrGroup * numberOfGuests) {
                                                                        setOption2Chosen(option2Chosen + 1)
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>}
                                            {
                                                (params.selected.option2Detail.multiplyByPeople && (numberOfGuests + numberOfKid) <= option2Remaining) &&
                                                <input
                                                    type="checkbox"
                                                    defaultChecked={option2Chosen}
                                                    onChange={() => option2Chosen ? setOption2Chosen(0) : setOption2Chosen(numberOfGuests)}
                                                />}
                                        </div>
                                        <p className="option-description">{params.selected.option2Description}</p>
                                    </div>
                                }
                            </div>
                        }
                        <div id="booking-next-step-wrapper">
                            {/* <button id="booking-next-step">COMPLET</button> */}
                            <button id="booking-next-step"
                                onClick={() => {
                                    if ((!vipAvailable && menu1count + menu2count < numberOfGuests) || (params.selected.pairBooking && ((numberOfGuests + numberOfKid) % 2 > 0))) {
                                        return
                                    }
                                    if (vipOption && numberOfGuests < 10) {
                                        setTenPeoleWarning(true)
                                        setTimeout(() => {
                                            setTenPeoleWarning(false)
                                        }, 2000);
                                    } else {
                                        if (maxStep < 2) {
                                            setMaxStep(2)
                                        }
                                        history.push({
                                            pathname: "/booking",
                                            search: "?step=2"
                                        })
                                        setTimeout(() => {
                                            window.scrollTo({ top: 0, behavior: "smooth" })
                                        }, 50);
                                    }
                                }}
                            >{!vipAvailable && menu1count + menu2count < numberOfGuests ? "veuillez choisir vos menus" : (params.selected.pairBooking && ((numberOfGuests + numberOfKid) % 2 > 0)) ? "Le nombre de réservation doit être pair" : "étape suivante"}</button>
                        </div>
                    </div>
                }
                {
                    actualStep === 2 &&
                    <div
                        style={{
                            width: "100%"
                        }}
                    >
                        <div id="step-2-wrapper">
                            <div
                                className="form-and-stripe"
                            >
                                <div
                                    className="form"
                                >
                                    <div id="booking-gender-choice-wrapper"
                                        className="label-and-input"
                                    >
                                        <p
                                            className="product-text form-text"
                                        >Civilité :</p>
                                        <div
                                            id="booking-gender-choice"
                                        >
                                            <div className="booking-gender-button"
                                                style={{
                                                    borderRight: "1px solid rgb(242, 135, 5)",
                                                    backgroundColor: bookingData.gender === "Monsieur" ?
                                                        "white" : "rgb(200,200,200)"
                                                }}
                                                onClick={() => SetBookingData(bd => ({
                                                    bd,
                                                    gender: "Monsieur"
                                                }))}
                                            >
                                                <div className="booking-gender-dot"
                                                    style={{
                                                        backgroundColor: bookingData.gender === "Monsieur" ?
                                                            "black" : "white"
                                                    }}
                                                />
                                                <p
                                                    className="gender-input"
                                                    style={{
                                                        textDecoration: bookingData.gender === "Monsieur" ?
                                                            "underline" : "none"
                                                    }}
                                                >Monsieur</p>
                                            </div>
                                            <div className="booking-gender-button"
                                                style={{
                                                    borderLeft: "1px solid rgb(242, 135, 5)",
                                                    backgroundColor: bookingData.gender === "Monsieur" ?
                                                        "rgb(200,200,200)" : "white"
                                                }}
                                                onClick={() => SetBookingData(bd => ({
                                                    bd,
                                                    gender: "Madame"
                                                }))}
                                            >
                                                <div className="booking-gender-dot"
                                                    style={{
                                                        backgroundColor: bookingData.gender === "Monsieur" ?
                                                            "white" : "black"
                                                    }}
                                                />
                                                <p
                                                    className="gender-input"
                                                    style={{
                                                        textDecoration: bookingData.gender === "Monsieur" ?
                                                            "none" : "underline"
                                                    }}
                                                >Madame</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="label-and-input"
                                    >
                                        <p
                                            className="product-text form-text"
                                        >Nom :</p>
                                        <input
                                            id="name"
                                            className="form-input simple-input"
                                            value={bookingData.name}
                                            onChange={(e) => {
                                                SetBookingData({
                                                    ...bookingData,
                                                    name: e.target.value
                                                })
                                                e.target.style.backgroundColor = "white"
                                            }}

                                        ></input>
                                    </div>
                                    <div
                                        className="label-and-input"
                                    >
                                        <p
                                            className="product-text form-text"
                                        >Prénom :</p>
                                        <input
                                            id="firstName"
                                            className="form-input simple-input"
                                            value={bookingData.firstName}
                                            onChange={(e) => {
                                                SetBookingData({
                                                    ...bookingData,
                                                    firstName: e.target.value
                                                })
                                                e.target.style.backgroundColor = "white"
                                            }}
                                        ></input>
                                    </div><div
                                        className="label-and-input"
                                    >
                                        <p
                                            className="product-text form-text"
                                        >Entreprise : (facultatif)</p>
                                        <input
                                            id="enterprise"
                                            className="form-input simple-input"
                                            value={bookingData.enterprise}
                                            onChange={(e) => {
                                                SetBookingData({
                                                    ...bookingData,
                                                    enterprise: e.target.value
                                                })
                                                e.target.style.backgroundColor = "white"
                                            }}
                                        ></input>
                                    </div>
                                    <div
                                        className="label-and-input"
                                    >
                                        <p
                                            className="product-text form-text"
                                        >Pays / Région :</p>
                                        <select name="country"
                                            id="country"
                                            className="form-input"
                                            onChange={(e) => {
                                                SetBookingData({
                                                    ...bookingData,
                                                    country: e.target.value
                                                })
                                                e.target.style.backgroundColor = "white"
                                            }}
                                            value={bookingData.country}
                                        >
                                            {countries.map((country) => {
                                                return (
                                                    <option key={country} value={country}>{country}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div
                                        className="label-and-input"
                                    >
                                        <p
                                            className="product-text form-text"
                                        >Ville :</p>
                                        <input
                                            id="city"
                                            className="form-input simple-input"
                                            value={bookingData.city}
                                            onChange={(e) => {
                                                SetBookingData({
                                                    ...bookingData,
                                                    city: e.target.value
                                                })
                                                e.target.style.backgroundColor = "white"
                                            }}
                                        ></input>
                                    </div>
                                    <div
                                        className="label-and-input"
                                    >
                                        <p
                                            className="product-text form-text"
                                        >Code Postal :</p>
                                        <input
                                            id="zipCode"
                                            className="form-input simple-input"
                                            value={bookingData.zipCode}
                                            onChange={(e) => {
                                                SetBookingData({
                                                    ...bookingData,
                                                    zipCode: e.target.value
                                                })
                                                e.target.style.backgroundColor = "white"
                                            }}
                                        ></input>
                                    </div>
                                    <div
                                        className="label-and-input"
                                    >
                                        <p
                                            className="product-text form-text"
                                        >Adresse :</p>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column"
                                            }}
                                        >
                                            <input
                                                id="address"
                                                className="form-input simple-input"
                                                value={bookingData.address}
                                                onChange={(e) => {
                                                    SetBookingData({
                                                        ...bookingData,
                                                        address: e.target.value
                                                    })
                                                    e.target.style.backgroundColor = "white"
                                                }}
                                            ></input>
                                        </div>
                                    </div>
                                    <div
                                        className="label-and-input"
                                    >
                                        <p
                                            className="product-text form-text"
                                        >Téléphone :</p>
                                        <input
                                            id="phone"
                                            className="form-input simple-input"
                                            value={bookingData.phone}
                                            onChange={(e) => {
                                                SetBookingData({
                                                    ...bookingData,
                                                    phone: e.target.value
                                                })
                                                e.target.style.backgroundColor = "white"
                                            }}
                                        ></input>
                                    </div>
                                    <div className="label-and-input">
                                        <p
                                            className="product-text form-text"
                                        >Complément :</p>
                                        <input
                                            id="address2"
                                            className="form-input simple-input"
                                            value={bookingData.address2}
                                            onChange={(e) => {
                                                SetBookingData({
                                                    ...bookingData,
                                                    address2: e.target.value
                                                })
                                                e.target.style.backgroundColor = "white"
                                            }}
                                        ></input>
                                    </div>
                                    <div
                                        className="label-and-input"
                                    >

                                        <p
                                            className="product-text form-text"
                                        >e-mail :</p>
                                        <input
                                            id="email"
                                            className="form-input simple-input"
                                            value={bookingData.email}
                                            onChange={(e) => {
                                                SetBookingData({
                                                    ...bookingData,
                                                    email: e.target.value
                                                })
                                                e.target.style.backgroundColor = "white"
                                            }}
                                        ></input>
                                    </div>
                                    {/* {
                            vipAvailable &&
                            <div
                                className="checkbox-div"
                                id="first-checkbox"
                            >
                                <input
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    className="form-checkbox"
                                    onChange={() => setVipOption(!vipOption)}
                                    type="checkbox"
                                    id="partner-offers"
                                    defaultChecked={vipOption}
                                >
                                </input>
                                <label
                                    className="checkbox-text"
                                    for="partner-offers">Option VIP&nbsp;
                                </label>
                                <p
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    onClick={() => setMoreInfo(!moreInfo)}
                                    id="more-info-vip"
                                >plus d'informations</p>
                            </div>
                        }
                        {
                            moreInfo &&
                            <div id="vip-info-div">
                                <p>{params.selected.vipText}</p>
                            </div>
                        } */}
                                </div>

                            </div>
                            <MyBookingDetails
                                {...params.selected}
                                promoAmount={promoAmount * numberOfGuests}
                                handleFrameClick={handleFrameClick}
                                eventName={params.selected.title}
                                price={vipOption ? params.selected.vipPrice * numberOfGuests + params.selected.vipKidPrice * numberOfKid - (promoAmount * numberOfGuests) :
                                    params.selected.price * numberOfGuests + params.selected.kidPrice * numberOfKid + option1Chosen * params.selected.option1Price + option2Chosen * params.selected.option2Price - (promoAmount * numberOfGuests)}
                                date={params.chosenDate.toLocaleDateString(params.language)}
                                day={params.chosenDate.toLocaleDateString('fr-FR', { weekday: 'long' })}
                                requestMode={false}
                                vipOption={vipOption}
                                numberOfGuests={numberOfGuests}
                                numberOfKid={numberOfKid}
                                kidPrice={vipOption ? params.selected.vipKidPrice : params.selected.kidPrice}
                                adultPrice={vipOption ? params.selected.vipPrice : params.selected.price}
                                optionPrice={option1Chosen * params.selected.option1Price + option2Chosen * params.selected.option2Price}
                            />
                        </div>
                        <div id="booking-next-step-wrapper">
                            <button id="booking-next-step"
                                onClick={() => {
                                    if (formOK && isEmailValid) {
                                        if (vipOption && numberOfGuests < 10) {
                                            setTenPeoleWarning(true)
                                            setTimeout(() => {
                                                setTenPeoleWarning(false)
                                            }, 2000);
                                        } else {
                                            if (maxStep < 3) {
                                                setMaxStep(3)
                                            }
                                            history.push({
                                                pathname: "/booking",
                                                search: "?step=3"
                                            })
                                            setTimeout(() => {
                                                window.scrollTo({ top: 0, behavior: "smooth" })
                                            }, 50);
                                        }
                                    } else {
                                        let list = compareFields(true)
                                        list.forEach(e => {
                                            let elem = document.getElementById(e)
                                            if (elem) {
                                                elem.style.backgroundColor = "rgb(255, 125, 125)"
                                            }
                                        })
                                        if (!isEmailValid) {
                                            let elem = document.getElementById("email")
                                            if (elem) {
                                                elem.style.backgroundColor = "rgb(255, 125, 125)"
                                            }
                                        }
                                        setNextStepButton("Veuillez remplir les champs en rouge")
                                    }
                                }}
                            >{nextStepButton}</button>
                        </div>
                    </div>
                }
                {
                    actualStep === 3 &&
                    <div id="step-3-wrapper">
                        {
                            !hideMBD &&
                            <MyBookingDetails
                                {...params.selected}
                                promoAmount={promoAmount * numberOfGuests}
                                handleFrameClick={handleFrameClick}
                                eventName={params.selected.title}
                                price={vipOption ? params.selected.vipPrice * numberOfGuests + params.selected.vipKidPrice * numberOfKid - (promoAmount * numberOfGuests) :
                                    params.selected.price * numberOfGuests + params.selected.kidPrice * numberOfKid + option1Chosen * params.selected.option1Price + option2Chosen * params.selected.option2Price - (promoAmount * numberOfGuests)}
                                date={params.chosenDate.toLocaleDateString(params.language)}
                                day={params.chosenDate.toLocaleDateString('fr-FR', { weekday: 'long' })}
                                requestMode={false}
                                vipOption={vipOption}
                                numberOfGuests={numberOfGuests}
                                numberOfKid={numberOfKid}
                                kidPrice={vipOption ? params.selected.vipKidPrice : params.selected.kidPrice}
                                adultPrice={vipOption ? params.selected.vipPrice : params.selected.price}
                                optionPrice={option1Chosen * params.selected.option1Price + option2Chosen * params.selected.option2Price}
                            />}
                        <div id="coupon-div">
                            <p id="coupon-title">Bons d'achat et codes Promo</p>
                            <div id="code-input-div">
                                <input id="coupon-input"
                                    value={promoCode}
                                    maxLength="12"
                                    onClick={() => {
                                        if (promoCode === "Code promo") {
                                            setPromoCode("")
                                        }
                                    }}
                                    onChange={(e) => {
                                        setPromoCode(e.target.value.toUpperCase())
                                    }}
                                />
                                <button id="coupon-submit"
                                    onClick={() => callPromo()}
                                >UTILISER</button>
                            </div>
                        </div>
                        <div id="step-3-split" />
                        <div id="step-3-total">
                            <p className="step-3-total">TOTAL</p>
                            <p className="step-3-total">{vipOption ? params.selected.vipPrice * numberOfGuests + params.selected.vipKidPrice * numberOfKid :
                                params.selected.price * numberOfGuests + params.selected.kidPrice * numberOfKid + option1Chosen * params.selected.option1Price + option2Chosen * params.selected.option2Price - (promoAmount * numberOfGuests)} €</p>
                        </div>
                        <div id="booking-next-step-wrapper">
                            <button id="booking-next-step"
                                onClick={() => {
                                    if (vipOption && numberOfGuests < 10) {
                                        setTenPeoleWarning(true)
                                        setTimeout(() => {
                                            setTenPeoleWarning(false)
                                        }, 2000);
                                    } else {
                                        if (maxStep < 4) {
                                            setMaxStep(4)
                                        }
                                        history.push({
                                            pathname: "/booking",
                                            search: "?step=4"
                                        })
                                        setTimeout(() => {
                                            window.scrollTo({ top: 0, behavior: "smooth" })
                                        }, 50);
                                    }
                                }}
                            >étape suivante</button>
                        </div>
                    </div>
                }
                {
                    actualStep === 4 &&
                    <div id="step-4-wrapper">
                        <div className="form-and-stripe"
                            id="stripe-wrapper"
                        >
                            <div
                                className="stripe"
                            >
                                {!isEmailValid && <div
                                    id="stripe-hide"
                                >
                                    <p
                                        className="hide-text"
                                    >Veuillez entrer vos informations avant de pouvoir procéder au paiement</p>
                                    <p
                                        className="hide-text"
                                    >
                                        verifiez que l'adresse mail est exacte</p>
                                </div>}
                                {/* <div
                                className="price"
                            >
                                <div
                                    className="label-and-input guest-and-price"
                                >
                                    <p
                                        className="product-text form-text"
                                    >Nombre de personnes :</p>
                                    <input
                                        className="form-input"
                                        value={numberOfGuests}
                                        onChange={(e) => {
                                            if (vipOption) {
                                                if (e.target.value >= 10) {
                                                    setNumberOfGuests(e.target.value)
                                                }
                                            } else {
                                                setNumberOfGuests(e.target.value)
                                            }
                                        }}
                                        type="number"
                                        min="1"
                                        max="100"
                                    ></input>
                                </div>
                                <div
                                    className="label-and-input guest-and-price"
                                >
                                    <p
                                        className="product-text form-text"
                                    >Prix :</p>
                                    <p
                                        className="product-text form-text"
                                    >{price / 100} €</p>
                                </div>
                            </div> */}
                                {clientSecret && (
                                    <Elements options={options}
                                        stripe={promise}
                                    >
                                        <CheckoutForm
                                            setMaxStep={setMaxStep}
                                            sendBooking={sendBooking}
                                            setBookingResponse={setBookingResponse}
                                            bookingResponse={bookingResponse}
                                            buildRecapObject={buildRecapObject}
                                            returnAddress={params.returnAddress}
                                        />
                                    </Elements>

                                )}
                            </div>
                        </div>
                        <MyBookingDetails
                            {...params.selected}
                            promoAmount={promoAmount * numberOfGuests}
                            handleFrameClick={handleFrameClick}
                            eventName={params.selected.title}
                            price={vipOption ? params.selected.vipPrice * numberOfGuests + params.selected.vipKidPrice * numberOfKid - (promoAmount * numberOfGuests) :
                                params.selected.price * numberOfGuests + params.selected.kidPrice * numberOfKid + option1Chosen * params.selected.option1Price + option2Chosen * params.selected.option2Price - (promoAmount * numberOfGuests)}
                            date={params.chosenDate.toLocaleDateString(params.language)}
                            day={params.chosenDate.toLocaleDateString('fr-FR', { weekday: 'long' })}
                            requestMode={false}
                            vipOption={vipOption}
                            numberOfGuests={numberOfGuests}
                            numberOfKid={numberOfKid}
                            kidPrice={vipOption ? params.selected.vipKidPrice : params.selected.kidPrice}
                            adultPrice={vipOption ? params.selected.vipPrice : params.selected.price}
                            optionPrice={option1Chosen * params.selected.option1Price + option2Chosen * params.selected.option2Price}
                        />
                    </div>
                }
                {
                    confirmedBooking && bookingFromCache &&
                    <div
                        className="pop-up-wrapper"
                    >
                        <div
                            className="pop-up"
                            id="succeeded-payment"
                        >
                            <p className="pop-up-text">
                                Merci !
                            </p>
                            <p className="pop-up-text">
                                Votre paiment a été accepté
                            </p>
                            <p
                                className="succeeded-payment-text"
                            >Vous allez recevoir un email de confirmation dans quelques instants...</p>
                            <div id="succeeded-payment-booking-number">
                                <div
                                    style={{
                                        width: "35%",
                                        marginLeft: "5%"
                                    }}
                                >
                                    <p
                                        className="succeeded-payment-text"
                                    >votre numéro de réservation est:</p>
                                </div>
                                <div
                                    style={{
                                        borderRadius: "8px",
                                        border: "solid 1px rgb(242, 135, 5)",
                                        width: "50%",
                                        marginLeft: "5%"
                                    }}
                                >
                                    <p
                                        className="booking-code-text"
                                        style={{
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            color: "rgb(242, 135, 5)",
                                        }}
                                    >{bookingFromCache.bookingCode}</p>
                                </div>
                            </div>
                            <p className="succeeded-payment-text">Vous pouvez accéder au détail de votre commande ou la modifier,
                            </p>
                            <p className="succeeded-payment-text">en renseignant email et code de réservation depuis l'espace &nbsp;
                                <span
                                    style={{
                                        fontWeight: "bold"
                                    }}
                                >"MA RESERVATION"</span></p>
                            <div id="succeeded-payment-links">
                                <Link to={`/mybooking?email=${bookingFromCache.email}&code=${bookingFromCache.bookingCode}`}><button
                                    onClick={() => {
                                        setPopup(false)
                                    }}
                                    className="pop-up-button"
                                >Accéder à ma réservation</button></Link>
                                <Link to="/events"><button
                                    onClick={() => {
                                        setPopup(false)
                                    }}
                                    className="pop-up-button"
                                >Effectuer une nouvelle réservation</button></Link>
                            </div>
                            <MyBookingDetails
                                // {...params.selected}
                                // promoAmount={promoAmount * numberOfGuests}
                                {...bookingFromCache}
                                handleFrameClick={handleFrameClick}
                            // eventName={params.selected.title}
                            // price={price / 100}
                            // date={params.chosenDate.toLocaleDateString(params.language)}
                            // day={params.chosenDate.toLocaleDateString('fr-FR', { weekday: 'long' })}
                            // requestMode={false}
                            // vipOption={vipOption}
                            // numberOfGuests={numberOfGuests}
                            // numberOfKid={numberOfKid}
                            // kidPrice={vipOption ? params.selected.vipKidPrice : params.selected.kidPrice}
                            // adultPrice={vipOption ? params.selected.vipPrice : params.selected.price}
                            // optionPrice={option1Chosen * params.selected.option1Price + option2Chosen * params.selected.option2Price}
                            />
                        </div>
                    </div>
                }
                <Footer />
            </div>
        )
    }
}

export default Booking